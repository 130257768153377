<template>
  <div class="bg-theme h-100-vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="card" style="margin-top: 25%">
            <div class="card-body text-center">
              <img
                src="/logo.svg"
                alt="logo"
                style="height: 100px"
                class="mb-4"
              />
              <div v-if="!loading">
                <div v-if="success">
                  <div class="mb-3">
                    <i
                      class="fa fa-envelope text-success p-3 bg-light-blue round-50"
                      style="font-size: 30px"
                    ></i>
                  </div>
                  <h4>Account Activated!</h4>
                  <p class="text-muted">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Corrupti, rerum? Quisquam nobis, earum provident.
                  </p>
                  <router-link
                    :to="{ name: 'login' }"
                    class="btn btn-primary w-100"
                    >Continue <i class="fa fa-arrow-right ms-2"></i
                  ></router-link>
                </div>
                <div v-if="!success">
                  <div class="mb-3">
                    <i
                      class="fad fa-exclamation-triangle text-danger p-3 bg-light-blue round-50"
                      style="font-size: 30px"
                    ></i>
                  </div>
                  <h4>Activation Failed!</h4>
                  <p class="text-muted">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Corrupti, rerum? Quisquam nobis, earum provident.
                  </p>
                </div>
              </div>
              <div v-if="loading">
                <img src="/assets/dots.svg" alt="loading" />
                <p class="mt-2">Please wait...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      return this.$router.push({ name: 'dashboard' })
    }
    
    this.fetch();
  },

  data() {
    return {
      loading: false,
      success: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .post("/api/v1/verify-email", { token: this.$route.params.token })
        .then(() => {
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
  },
};
</script>
